import { useState } from "react";
import { Outlet } from "react-router-dom";
import LoginPage from "../../pages/LoginPage/LoginPage";
import NavHeader from "../Header/Header";
import Footer from "../Footer/Footer";
const Root = () => {
  const [token, setToken] = useState(
    sessionStorage.getItem("token")
      ? JSON.parse(sessionStorage.getItem("token") || "false")
      : false
  );

  if (!token) {
    return <LoginPage setToken={setToken} />;
  }

  return (
    <>
      {/* <UcmpNavbar projectName="SAS2OS" /> */}
      <NavHeader />
      <Outlet />
      <Footer />
    </>
  );
};

export default Root;
