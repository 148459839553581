import { Box, Typography, useTheme } from "@mui/material";

import { useState } from "react";
import { UcmpButton, UcmpNavbar } from "ui-components-marketplace";
import LoginBackground from "../../Assets/images/login.svg";
import './login.css';
import { useNavigate } from "react-router-dom";


const LoginPage = ({ setToken }: any) => {
  const theme = useTheme();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();


  const handleLogin = () => {
    if (email === "admin" && password === "Deloitte") {
      sessionStorage.setItem("token", JSON.stringify(true));
      sessionStorage.setItem('username', email);
      setToken(true);
      navigate('/');
    }
    else 
      {
      window.alert("Invalid credentials");
    }
  };

  return (
    <>
      <UcmpNavbar projectName="SAS Modernizer" className="" />
      <Box
        className={"main-class"}
        sx={{
          backgroundImage: `url(${LoginBackground})`,
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          padding={4}
          color={"#fff"}
          height={1}
          borderRadius={2}
          mx={6}
          gap={theme.spacing(2)}
          sx={{ backgroundColor: "#000", minWidth: 400 }}
        >
          <Typography variant="h4" fontWeight={300}>
            Login
          </Typography>
          <Typography variant="h6">
            Welcome, please enter your details
          </Typography>
          <Box
            component={"form"}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Box display={"block"} width={1}>
              <label className={'ucmplabel-class'}>
                <Typography variant="body2" mb={1}>
                  Email ID
                </Typography>
                <input
                  type="email"
                  placeholder="Enter email"
                  className={"input-class"}
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
            </Box>
            <Box display={"block"} width={1}>
              <label className={'ucmplabel-class'}>
                <Typography variant="body2" mb={1}>
                  Password
                </Typography>
                <input
                  type="password"
                  placeholder="Enter password"
                  className={"input-class"}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
            </Box>
            <Box>
              <UcmpButton variant="text" sx={{ color: "#fff" }}>
                Forgot password?
              </UcmpButton>
            </Box>
            <Box>
              <UcmpButton
                color="info"
                sx={{ borderRadius: 0 }}
                onClick={handleLogin}
              >
                Log in
              </UcmpButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;
