import { Header, HeaderProp } from "@usitsdasdesign/dds-react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Header.css";
import { useSelector } from "react-redux";
import { GlboalState } from "../../redux/store";

const NavHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const loading = useSelector((state: GlboalState) => state.loading.loading);
  const userName = sessionStorage.getItem("username");

  const headerTitles = [];

  const args: HeaderProp = {
    projectName: "SAS Modernizer",
    customClass: "Nav-header",
    isResponsive: true,
    navigationMenu: !loading
      ? [
          {
            title: "Home",
            isActive: location.pathname === "/",
            onClick: () => navigate("/"),
            ariaLabel: "",
          },
          {
            title: "Migration assistant",
            isActive: location.pathname === "/assistant",
            ariaLabel: "Migration assistant",
            onClick: () => {
              navigate("/assistant");
            },
          },
          {
            title: "Reverse engineering",
            isActive: location.pathname === "/reverse_engineering",
            ariaLabel: "Reverse engineering",
            onClick: () => {
              navigate("/reverse_engineering");
            },
          },
        ]
      : [],
  };

  return (
    <>
      <Header {...args} />
    </>
  );
};

export default NavHeader;
