import { configureStore } from "@reduxjs/toolkit";
import RootReducer from "./reducer";
import migrationAssistanceReducer from "./migrationAssistanceReducer";
import reverseEngineerReducer from "./reverseEngineerReducer";
import loadingReducer from "./loadingReducer";

const store = configureStore({
  reducer: {
    root: RootReducer,
    migrationAssistance: migrationAssistanceReducer,
    reverseEngineer: reverseEngineerReducer,
    loading: loadingReducer,
  },
});

export type GlboalState = ReturnType<typeof store.getState>;
export default store;
