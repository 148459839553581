import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { data: {} };

const migrationAssistanceSlice = createSlice({
  name: "migrationAssistance",
  initialState,
  reducers: {
    updateMigrationAssistance: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const { updateMigrationAssistance } = migrationAssistanceSlice.actions;
export default migrationAssistanceSlice.reducer;
