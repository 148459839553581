export const apiBaseUrl = window.env.API_BASE_URL || process.env.REACT_APP_API_BASE_URL;
export const urlConstant = {
  upload: {
    method: "POST",
    endpoint: `${apiBaseUrl}/utils/upload`,
  },
  migrationAssistant: {
    method: "GET",
    endpoint: `${apiBaseUrl}/migration_assistant`,
  },
  lineage: {
    method: "GET",
    endpoint: `${apiBaseUrl}/lineage`,
  },
};

export const CDN_URL = window.env.CDN_URL || process.env.REACT_APP_CDN_URL + "/";
export const CDN_SIGN = window.env.CDN_SIGN || process.env.REACT_APP_CDN_SIGN;
export const LOGO_URL = `${CDN_URL}logo.gif${CDN_SIGN}`;
export const BACKGROUND_IMAGE = `${CDN_URL}Background.gif${CDN_SIGN}`;
