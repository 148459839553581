import { Box } from "@mui/material";
import { BACKGROUND_IMAGE, LOGO_URL } from "../../api/constants";

const Loader = () => {
  return (
    <>
      <Box
        minHeight="calc(100vh - 180px)"
        sx={{
          backgroundImage: `url(${BACKGROUND_IMAGE})`,
          backgroundSize: "cover",
          opacity: 0.3,
        }}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img src={LOGO_URL} alt="Deloitte AI & Data assets hub." height="300" />
      </Box>
    </>
  );
};

export default Loader;
