import { ThemeOptions, createTheme } from "@mui/material";

const themeOptions: ThemeOptions = {
  palette: {
    grey: {
      "100": "#ffffff",
      "800": "#1b1d1b",
      "900": "#000000",
    },
    primary: {
      // main: "#53565a",
      main: "#26890d",
    },
    secondary: {
      main: "#005587",
      light: "#ddefe8",
    },
    success: {
      main: "#26890d",
    },
    info: {
      main: "#007cb0",
    },
    warning: {
      main: "#ffcd00",
    },
    error: {
      main: "#da291c",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "'Open Sans', Verdana, Helvetica, sans-serif",
    },
    fontFamily: "'Open Sans', Verdana, Helvetica, sans-serif",
    button: {
      textTransform: "none",
    },
  },
};

const customTheme = {
  palette: {
    deloitteGreen: createTheme(themeOptions).palette.augmentColor({
      color: {
        main: "#86bc25",
      },
      name: "deloitte-green",
    }),
    grey: createTheme(themeOptions).palette.augmentColor({
      color: {
        main: "#000000",
        "800": "#1b1d1b",
      },
      name: "grey",
    }),
  },
};

export const theme = createTheme(themeOptions, customTheme);
