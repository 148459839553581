import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { data: {} };

const reverseEngineerSlice = createSlice({
  name: "reverseEngineer",
  initialState,
  reducers: {
    updateReverseEngineer: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const { updateReverseEngineer } = reverseEngineerSlice.actions;
export default reverseEngineerSlice.reducer;
