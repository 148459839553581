import { Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { urlConstant } from "../../api/constants";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { GlboalState } from "../../redux/store";
import { updateReverseEngineer } from "../../redux/reverseEngineerReducer";
import SummaryTable from "../../components/SummaryTable/SummaryTable";
import Banner from "../../components/Banner/Banner";
import { updateLoading } from "../../redux/loadingReducer";

function About() {
  const dispatchToStore = useDispatch();
  const reverseEngineerData = useSelector(
    (state: GlboalState) => state.reverseEngineer.data
  );
  const [res, setRes] = useState<string>();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [rows, setRows] = useState<any>();
  useEffect(() => {
    const getResponse = async () => {
      try {
        setShowLoader(true);
        dispatchToStore(updateLoading(true));
        const res = await axios.request({
          url: urlConstant.lineage.endpoint,
          method: urlConstant.lineage.method,
        });
        if (res?.status === 200) {
          dispatchToStore(updateReverseEngineer(res.data.data));
          setRes(res.data.data);
          setShowLoader(false);
          dispatchToStore(updateLoading(false));
        } else {
          setShowLoader(false);
          dispatchToStore(updateLoading(false));
          alert("Something went wrong");
        }
      } catch (e) {
        setShowLoader(false);
        dispatchToStore(updateLoading(false));
        console.log(e);
      }
    };
    if (!Object.keys(reverseEngineerData).length) getResponse();
    else {
      setRes(reverseEngineerData);
    }
  }, []);
  useEffect(() => {
    if (reverseEngineerData) {
      let rows: any = [];
      {
        Object.entries(reverseEngineerData).map(([key, value]: any) =>
          rows.push(createData(key, value))
        );
      }
      setRows(rows.slice(1));
    }
  }, [reverseEngineerData]);
  function createData(metric: string, value: string | number | string[]) {
    return {
      metric,
      value,
    };
  }
  return (
    <>
      <Box paddingTop={6}>
        <Banner
          text="Reverse engineer"
          description="Provides information on source and target tables of the SAS code."
        />
        {showLoader && <Loader />}
        {res && (
          <Box paddingBottom={10}>
            <SummaryTable rows={rows}></SummaryTable>
          </Box>
        )}
      </Box>
    </>
  );
}
export default About;
