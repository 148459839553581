import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { DocType } from "../../utils/types";
import Markdown from "react-markdown";
interface DocGenProps {
  code: string;
  filteredCode: string;
  filteredDoc: string;
  segments: DocType;
  handleDropdownChange: (event: SelectChangeEvent) => void;
}
export const DocumentGeneration = ({
  code,
  filteredCode,
  filteredDoc,
  segments,
  handleDropdownChange,
}: DocGenProps) => {
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        paddingTop={"2px"}
        paddingBottom={"2px"}
      >
        <FormControl>
          <InputLabel id="demo-simple-select-label">Code segments</InputLabel>
          <Select
            sx={{ width: "150px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={code}
            label="Code segments"
            onChange={handleDropdownChange}
          >
            {segments &&
              Object.entries(segments).map(([key, value]) => (
                <MenuItem value={key}>{value}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      {code ? (
        <>
          <Box width={"100%"} sx={{ backgroundColor: "black" }}>
            <Typography variant="h6" sx={{ color: "white", paddingLeft: 1 }}>
              SAS Code
            </Typography>
          </Box>
          <Box paddingLeft={2}>
            <SyntaxHighlighter language={"sas"}>
              {filteredCode}
            </SyntaxHighlighter>
          </Box>
          <Box width={"100%"} sx={{ backgroundColor: "black" }}>
            <Typography variant="h6" sx={{ color: "white", paddingLeft: 1 }}>
              Documentation
            </Typography>
          </Box>
          <Box paddingBottom={5}>
            <Box paddingLeft={2}>
              <Markdown>{filteredDoc}</Markdown>
            </Box>
          </Box>
        </>
      ) : (
        <Box display={"flex"} justifyContent={"center"} paddingTop={7}>
          <Typography>
            Please select a segment from the dropdown to view document generated
            for you
          </Typography>
        </Box>
      )}
    </>
  );
};
