import { Box, Typography, useTheme } from "@mui/material";
import commonHeaderBackground from "./../../Assets/images/header-bg.svg";
const Banner = (props: any) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          //height: "20%",
          //height: "124px",
          overflow: "hidden",
          backgroundImage: `url(${commonHeaderBackground})`,
          backgroundColor: "black",
          backgroundSize: "cover",
          px: 2,
          paddingBottom: 1,
          // py: 0.5,

          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          gap: 2,

          ...(props.sx ?? {}),
        }}
      >
        <Box py={1} paddingTop={0}>
          {/* {props.breadCrumbs ? props.breadCrumbs : null} */}
          <Box
            display="flex"
            flexDirection={"row"}
            gap={1.5}
            alignItems={"flex-end"}
          >
            <Typography variant="h5" color="white" fontWeight={500} mt={1}>
              {props.text}
            </Typography>
          </Box>

          {props.description && (
            <Box width="100%">
              <Typography
                color="white"
                fontSize={14}
                fontWeight="regular"
                mt={1.5}
                className="banner-description"
              >
                {props.description}
              </Typography>
            </Box>
          )}
        </Box>

        {props.cta && (
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            minWidth={"10%"}
            height={"78px"}
            alignSelf="center"
            pr={2}
          >
            {props.cta}
          </Box>
        )}
        {/* {props.logoImage && (
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            minWidth={"20%"}
            pr={3}
          >
            {props.logoImage}
          </Box>
        )} */}
      </Box>
    </>
  );
};

export default Banner;
