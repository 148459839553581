import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import MermaidChart from "../../components/Mermaid/MermaidChart";
import { DocType } from "../../utils/types";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

interface LogicalFlowChartProps {
  code: string;
  segments: DocType;

  filteredCode: string;
  filteredFlowChart: string | undefined;

  handleDropdownChange: (event: SelectChangeEvent) => void;
}

export const LogicalFlowChart = ({
  code,
  filteredCode,
  filteredFlowChart,
  segments,
  handleDropdownChange,
}: LogicalFlowChartProps) => {
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        paddingTop={"2px"}
        paddingBottom={"2px"}
      >
        <FormControl>
          <InputLabel id="demo-simple-select-label">Code segments</InputLabel>
          <Select
            sx={{ width: "150px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={code}
            label="Code segments"
            onChange={handleDropdownChange}
          >
            {segments &&
              Object.entries(segments).map(([key, value]) => (
                <MenuItem value={key}>{value}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      {code ? (
        <Grid container spacing={2} paddingTop={2}>
          <Grid xs={12} sm={6}>
            <Box>
              <Box
                width={"99%"}
                sx={{ backgroundColor: "black" }}
                display={"flex"}
                justifyContent={"center"}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "white", paddingLeft: 5 }}
                >
                  SAS Code
                </Typography>
              </Box>
              <Box paddingLeft={2}>
                <SyntaxHighlighter language={"sas"}>
                  {filteredCode}
                </SyntaxHighlighter>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={6}>
            <Box width={"99%"} sx={{ backgroundColor: "black" }}>
              <Typography variant="h6" sx={{ color: "white", paddingLeft: 1 }}>
                Flow chart
              </Typography>
            </Box>
            <Box paddingBottom={5} display="flex" justifyContent={"center"}>
              {filteredFlowChart && <MermaidChart chart={filteredFlowChart} />}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box display={"flex"} justifyContent={"center"} paddingTop={7}>
          <Typography>
            Please select a segment from the dropdown to view logical flow chart
            generated for you
          </Typography>
        </Box>
      )}
    </>
  );
};
