import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        display: "block",
        width: "100%",
        backgroundColor: "black",
        boxSizing: "border-box",
        p: "14px 16px"
      }}
      py={2}
      px={1}
      marginTop="auto"
      bottom={0}
      zIndex={11}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Typography fontSize={13} fontWeight={400} color="white" sx={{
            ":hover": {
              textDecoration: 'underline'
            }
          }}>
          <Link to="https://resources.deloitte.com/sites/global/Pages/Legal.aspx" target="_blank" style={{color: '#fff', textDecoration: 'none', }}>Legal</Link> 
          </Typography>
          <Typography fontSize={13} fontWeight={400} color="#53565A">
            |
          </Typography>
          <Typography fontSize={13} fontWeight={400} color="white"
          sx={{
            ":hover": {
              textDecoration: 'underline'
            }
          }}
          >
           <Link to="https://resources.deloitte.com/sites/kx/SitePages/privacy.aspx" target="_blank" style={{color: '#fff', textDecoration: 'none'}}>Privacy</Link> 
          </Typography>
        </Stack>
        <Typography fontSize={13} fontWeight={400}  color="#BBBCBC">
          © 2024. See Terms of Use for more information.
        </Typography>
      </Box>
    </Box>
  );
};
export default Footer;