import { useState } from "react";
import { DocType } from "../../utils/types";
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
interface MigrationConcernsProps {
  segments: DocType;
  viyaIssues: DocType;
  sasCode: DocType[];
}
export const MigrationConcerns = ({
  segments,
  viyaIssues,
  sasCode,
}: MigrationConcernsProps) => {
  const [code, setCode] = useState<string>("");

  const [filteredCode, setFilteredCode] = useState<string>("");
  const [filteredConcerns, setFilteredConcerns] = useState<string>();
  const nonNullSegments: DocType = {};
  Object.entries(viyaIssues).map(([key, value]) => {
    if (value !== "None") {
      nonNullSegments[key] = value;
    }
  });
  const segKeys = Object.keys(nonNullSegments);
  //const nonNullAbc: DocType = {};
  const segmentsWithConcerns = Object.fromEntries(
    Object.entries(segments).filter(([key]) => segKeys.includes(key))
  );

  const handleDropdownChange = (event: SelectChangeEvent) => {
    setCode(event.target.value as string);

    setFilteredCode(sasCode[0][event.target.value as string]);
    setFilteredConcerns(viyaIssues[event.target.value as string]);
  };
  return (
    <>
      {Object.keys(nonNullSegments).length !== 0 ? (
        <>
          <Box
            display={"flex"}
            justifyContent={"center"}
            paddingTop={"2px"}
            paddingBottom={"2px"}
          >
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Code segments
              </InputLabel>
              <Select
                sx={{ width: "150px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={code}
                label="Code segments"
                onChange={handleDropdownChange}
              >
                {segmentsWithConcerns &&
                  Object.entries(segmentsWithConcerns).map(([key, value]) => (
                    <MenuItem value={key}>{value}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          {filteredCode && (
            <Grid container spacing={2} paddingTop={2}>
              <Grid xs={12} sm={6}>
                <Box paddingBottom={8}>
                  <Box
                    width={"99%"}
                    sx={{ backgroundColor: "black" }}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: "white", paddingLeft: 5 }}
                    >
                      SAS Code
                    </Typography>
                  </Box>
                  <Box paddingLeft={2} paddingRight={1}>
                    <SyntaxHighlighter language={"sas"}>
                      {filteredCode}
                    </SyntaxHighlighter>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} sm={6}>
                <Box width={"99%"} sx={{ backgroundColor: "black" }}>
                  <Typography
                    variant="h6"
                    sx={{ color: "white", paddingLeft: 1 }}
                  >
                    Concerns
                  </Typography>
                </Box>
                <Container>{filteredConcerns}</Container>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <Box display={"flex"} justifyContent={"center"} paddingTop={10}>
          <Typography>
            Your file looks perfect. Feel free to upload other SAS file to see
            concerns if there are any
          </Typography>
        </Box>
      )}
    </>
  );
};
