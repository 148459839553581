import React from "react";
import SummaryTable from "../../components/SummaryTable/SummaryTable";
import { CodeSummaryRows } from "../../utils/types";

export interface CodeSummaryProps {
  rows: CodeSummaryRows[];
}
export const CodeSummary = ({ rows }: CodeSummaryProps) => {
  return <SummaryTable rows={rows} from="Code summary"></SummaryTable>;
};
