import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root/Root";
import LandingPage from "./pages/LandingPage/LandingPage";
import MigrationPage from "./pages/Migration Rules/MigrationPage";
import ReverseEngineer from "./pages/ReverseEngineer/ReverseEngineer";
// import LandingPage from './pages/LandingPage/LandingPage';

const Route = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },

      {
        path: "/assistant",
        element: <MigrationPage />,
      },
      {
        path: "/reverse_engineering",
        element: <ReverseEngineer />,
      },
    ],
  },
]);
export default Route;
