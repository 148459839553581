import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Loading {
  loading: boolean;
}

const initialState: Loading = { loading: false };

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    updateLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
  },
});

export const { updateLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
