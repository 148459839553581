import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UcmpButton } from "ui-components-marketplace";
import { MRT_RowSelectionState } from "material-react-table";
import axios from "axios";
import { urlConstant } from "../../api/constants";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import Loader from "../../components/Loader/Loader";
import { CodeSummaryType, CodeSummaryRows, DocType } from "../../utils/types";
import { CodeSummary } from "./CodeSummary";
import { DocumentGeneration } from "./DocumentGeneration";
import { LogicalFlowChart } from "./LogicalFlowChart";
import { MigrationConcerns } from "./MigrationConcerns";
import { useDispatch, useSelector } from "react-redux";
import { updateMigrationAssistance } from "../../redux/migrationAssistanceReducer";
import { GlboalState } from "../../redux/store";
import { updateLoading } from "../../redux/loadingReducer";

function createData(metric: string, value: string | number | string[]) {
  return {
    metric,
    value,
  };
}
function MigrationPage() {
  const navigate = useNavigate();
  const dispatchToStore = useDispatch();
  const [selectedRows, setSelectedRows] = React.useState<MRT_RowSelectionState>(
    {}
  );
  const [code, setCode] = useState<string>("");
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [selectedFeature, setSelectedFeature] = useState<string>("");
  const [codeSummery, setCodeSummery] = useState<CodeSummaryType>();
  const [rows, setRows] = useState<CodeSummaryRows[]>();
  useEffect(() => {
    if (codeSummery) {
      let rows: any = [];
      {
        Object.entries(codeSummery).map(([key, value]: any, index) =>
          rows.push(createData(key, value))
        );
      }
      setRows(rows);
    }
  }, [codeSummery]);

  const [docGeneration, setDocGeneration] = useState<DocType[]>([]);
  const [filteredCode, setFilteredCode] = useState<string>("");
  const [filteredDoc, setFilteredDoc] = useState<string>("");
  const [flowChart, setFlowChart] = useState<DocType>({});
  const [filteredFlowChart, setFilteredFlowChart] = useState<string>();
  const [segments, setSegments] = useState<DocType>({});
  // const [viyaIssues, setViyaIssues] = useState<DocType>({
  //   "0": "None",
  //   "1": "None",
  //   "2": "None",
  //   "3": "None",
  // });
  const [viyaIssues, setViyaIssues] = useState<DocType>({});

  const handleDropdownChange = (event: SelectChangeEvent) => {
    setCode(event.target.value as string);
    if (docGeneration) {
      setFilteredCode(docGeneration[0][event.target.value as string]);
      setFilteredDoc(docGeneration[1][event.target.value as string]);
      //This is a workaround as the flowchart was not getting rendered can
      //be removed once react mermaid package is updated
      setFilteredFlowChart(undefined);
      setTimeout(() => {
        setFilteredFlowChart(flowChart[event.target.value as string]);
      }, 1);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("file")) {
      navigate("/");
    }
  });
  const migrationAssistanceData = useSelector(
    (state: GlboalState) => state.migrationAssistance.data
  );

  useEffect(() => {
    const getResponse = async () => {
      try {
        setShowLoader(true);
        dispatchToStore(updateLoading(true));
        const res = await axios.request({
          url: urlConstant.migrationAssistant.endpoint,
          method: urlConstant.migrationAssistant.method,
        });
        if (res?.status === 200) {
          dispatchToStore(updateMigrationAssistance(res.data.data));
          setSegments(res.data.data.Segment_name_and_Line_No);
          setCodeSummery(res.data.data.code_summary);
          setDocGeneration(res.data.data.pseudocode);
          setFlowChart(res.data.data.flowchart);
          setViyaIssues(res.data.data.viya_issues);
          setShowLoader(false);
          dispatchToStore(updateLoading(false));
        } else {
          setShowLoader(false);
          dispatchToStore(updateLoading(false));
          alert("Something went wrong");
        }
      } catch (e) {
        setShowLoader(false);
        dispatchToStore(updateLoading(false));
        console.log(e);
      }
    };
    if (!Object.keys(migrationAssistanceData).length) getResponse();
    else {
      setSegments(migrationAssistanceData.Segment_name_and_Line_No);
      setCodeSummery(migrationAssistanceData.code_summary);
      setDocGeneration(migrationAssistanceData.pseudocode);
      setFlowChart(migrationAssistanceData.flowchart);
      setViyaIssues(migrationAssistanceData.viya_issues);
    }
  }, []);

  const features = [
    "Code summary",
    "Documentation",
    "Logical flow chart",
    "Migration concerns",
  ];
  const handleChange = (label: any, v: any) => {
    if (v) {
      setSelectedFeature(label);
    }
  };
  return (
    <>
      <Box paddingTop={6}>
        <Banner
          text="Migration assistant"
          description="Offers a detailed description, visual flowchart, and concise code summary to facilitate understanding of the SAS code."
        />
        {showLoader && <Loader />}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="left"
          justifyContent="left"
          style={{
            fontSize: 13,
            backgroundColor: "rgb(218 218 218)",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <FormGroup row style={{ marginLeft: 20 }}>
              {features.map((label, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                      checked={selectedFeature === label}
                      onChange={(name, v) => handleChange(label, v)}
                      name={label}
                    />
                  }
                  label={label}
                />
              ))}
            </FormGroup>
          </Box>
          <Box
            sx={{
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "5px",
            }}
          >
            {/* <UcmpButton>Execute</UcmpButton> */}
          </Box>
        </Box>
      </Box>
      {selectedFeature === "Code summary" && rows && (
        <CodeSummary rows={rows} />
      )}
      {selectedFeature === "Documentation" && (
        <DocumentGeneration
          code={code}
          filteredCode={filteredCode}
          filteredDoc={filteredDoc}
          segments={segments}
          handleDropdownChange={handleDropdownChange}
        />
      )}
      {selectedFeature === "Logical flow chart" && (
        <LogicalFlowChart
          code={code}
          filteredCode={filteredCode}
          filteredFlowChart={filteredFlowChart}
          segments={segments}
          handleDropdownChange={handleDropdownChange}
        />
      )}
      {selectedFeature === "Migration concerns" && viyaIssues && (
        <MigrationConcerns
          segments={segments}
          viyaIssues={viyaIssues}
          sasCode={docGeneration}
        />
      )}
    </>
  );
}
export default MigrationPage;
