import { Box, Typography } from "@mui/material";
import React from "react";
import { UcmpTable } from "ui-components-marketplace";
import { MRT_RowSelectionState } from "material-react-table";
import axios from "axios";
import { apiBaseUrl, urlConstant } from "../../api/constants";
import BackgroundSvg from "./../../Assets/images/bg.gif";
import Banner from "../../components/Banner/Banner";
import sas2osLogo from "./../../Assets/images/sas2os_logo_v2.png";
import {
  Color,
  UploadArea,
  UploadAreaProps,
  fileState,
} from "@usitsdasdesign/dds-react";
import "./LandingPage.sass";
import { useNavigate } from "react-router-dom";
import { updateMigrationAssistance } from "../../redux/migrationAssistanceReducer";
import { updateReverseEngineer } from "../../redux/reverseEngineerReducer";
import { useDispatch, useSelector } from "react-redux";
import { GlboalState } from "../../redux/store";

function LandingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = React.useState<fileState[]>([]);
  const [isError, setIsError] = React.useState<boolean>(false);
  const loading = useSelector((state: GlboalState) => state.loading.loading);
  const args: UploadAreaProps = {
    filesState: [],
    heading: "Upload file(s)",
    description: "supported file format .sas",
    dragAndDropText: "Drag and drop your sas files here to convert",
    isDragAndDrop: true,
    isMultiple: false,
    isError: isError,
    errorMessage: isError
      ? "Please upload SAS files to continue"
      : "This is error",
    isInversed: false,
    color: Color.green,
    buttonLabel: "Browse file",
    //chooseFileBtnKind: Color.primaryLoud,
    isDisabled: false,
    isRequired: true,
    unknownExtensionsIcon: "",
    allowFilesWithoutType: false,

    acceptFormats: [],
    acceptExtensions: ["sas"],
    restrictedFormats: ["image/*"],
    restrictedExtensions: ["docx", "pdf", "jpg", "png", "xlsx"],

    exceptSymbols: "",

    maxSymbolsName: 50,
    maxFiles: 20,
    maxTotalSize: 5000000000,
    maxSize: 20000000,
    minSize: 0,
    customClass: "upload-area-class",
    isDownloadable: true,
    receivedFiles: async (file: fileState[]) => {
      const formData = new FormData();
      file.forEach((file: fileState) => formData.append("file", file.data));
      setFile(file);
      if (file?.[0]) {
        if (file[0]?.extension !== "sas") {
          setIsError(true);
          const clonedFile = { ...file[0] };
          clonedFile.error = "File extension is not accepted";

          setFile([clonedFile]);
        } else {
          setIsError(false);
          try {
            // setIsLoading(true);
            const res = await axios.request({
              url: `${urlConstant.upload.endpoint}`,
              method: `${urlConstant.upload.method}`,
              data: formData,
            });
            if (res?.data?.message === "Uploaded successfully") {
              localStorage.setItem("file", file[0].name);
              dispatch(updateMigrationAssistance({}));
              dispatch(updateReverseEngineer({}));
              navigate("/assistant");
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    },

    deleteFile: (id: any) => {
      console.log("id", id);
      setFile([]);
      setIsError(false);
    },
    reuploadFile: (fileId: any) => {
      console.log("reuploadFile", fileId);
    },
  };
  return (
    <>
      {" "}
      <Box
        height="calc(100vh - 5px)"
        sx={{
          backgroundImage: `url(${BackgroundSvg})`,
          backgroundSize: "cover",
        }}
      >
        <Box paddingTop={6}>
          <Banner
            text="SAS modernizer"
            description="Aids in SAS to SAS Viya conversions by documenting SAS code, flagging issues, suggesting migration alternatives, splitting code for review, and creating pseudocode and flowcharts for better understanding."
            cta={<img src={sas2osLogo} alt="sas2os logo" />}
          />
          <Box display="flex" justifyContent={"center"}>
            <UploadArea {...args} filesState={file} isError={isError} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default LandingPage;
