import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CodeSummaryRows } from "../../utils/types";
import { Box } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  [`&.MuiTableCell-root`]: {
    padding: 4,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export interface CodeSummaryProps {
  rows: CodeSummaryRows[];
  from?: string;
}
const SummaryTable = ({ rows, from }: CodeSummaryProps) => {
  return (
    <Box display={"flex"} justifyContent={"center"} paddingTop={2}>
      <TableContainer component={Paper} sx={{ maxWidth: "50%" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {from ? "Category" : "Source table"}
              </StyledTableCell>
              <StyledTableCell>
                {from ? "Details" : "Target table"}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: CodeSummaryRows) => (
              <StyledTableRow key={row.metric}>
                <StyledTableCell component="th" scope="row">
                  {row.metric}
                </StyledTableCell>
                <StyledTableCell>
                  {Array.isArray(row.value) ? row.value.join(", ") : row.value}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default SummaryTable;
